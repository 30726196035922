<template>


    <carousel>
        <slide v-for="slide in slidemedias" :key="slide.title">
            <div class="slide_item ">
                <div class="slidetextwraper">
                    <div class="slidetitle ">{{ slide.title }}</div>
                    <div class="slidesubtitle ">{{ slide.subtitle }}</div>
                    <br/><div class="slidedescription ">{{ slide.description }}</div>
                    <fw-button :importclass="'fwbutton'" :text="'Read More'" @click="gotolink(slide.link)"  ></fw-button>
                </div>
                <div class="slidebgoverlay"></div>
                <div class="slideimg" :style="'background-image:url(/getSlideMedias3/'+ itemid +'/' + slide.filename+')'"></div>
            </div>
        </slide>
    </carousel>
</template>

<script>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import FwButton from "@/Pages/Frontend/Modules/FwButton.vue";
export default {
    name: "FwSlideshow",
    props: ['slidemedias', 'itemid'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        FwButton
    },

    methods:{
        gotolink(slidelink){
            window.open(slidelink);
        }
    }
}
</script>

<style scoped>

</style>
