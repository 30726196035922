<template>
<router-link to="">
    <div :class="importclass">

        <div class="fwbuttontext">{{text}}</div>
        <div class="fwbuttonbglev2"></div>
        <div class="fwbuttonbglev1"></div>
    </div>
</router-link>
</template>

<script>
export default {
    name: "FwButton",
    props: ['text', 'link','importclass'],


}
</script>

<style scoped>

</style>
