<template>


    <div class="w-screen h-screen flex flex-col p-8  justify-start items-start justify-items-start z-10 text-PShade-txt mt-32 ">

        <h1 class="text-2xl text-PShade-txt flex mb-4" >Payments History</h1>
        <h1 class="text-2xl text-PShade-txt flex" v-show="this.loaded && paylist.length < 1"> You dont have any payment</h1>

        <div v-for="payment in paylist" class="paymentblock">
            <div class="flex flex-col md:flex-row justify-start">
                <div class="payvoice"><span class="paylabel">ID</span> <span class="payvalue">{{ payment.id }}</span></div>
                <div class="payvoice"><span class="paylabel">Status</span> <span class="payvalue" :class="{'text-AccentInfos-green': payment.payment_status == 'approved', 'text-AccentInfos-warning': payment.payment_status == 'pending', 'text-[red]': payment.payment_status == 'failed'}">{{ payment.payment_status }}</span></div>
                <div class="payvoice"><span class="paylabel">Method</span> <span class="payvalue"> {{ payment.pay_method }}</span></div>
                <div class="payvoice"><span class="paylabel">Email</span> <span class="payvalue"> {{ payment.payer_email }}</span></div>
                <div class="payvoice"><span class="paylabel">Cart ID</span> <span class="payvalue"> {{ payment.pay_cart }}</span></div>
                <div class="payvoice"><span class="paylabel">Date</span> <span class="payvalue"> {{ new Date(payment.created_at).toLocaleDateString() }}</span></div>
                <div class="payvoice"><span class="paylabel">Price Paid</span> <span class="payvalue"> {{ payment.price }}$ </span></div>
            </div>
            <div class="flex justify-end grow">
                <div v-show="payment.invoice !== null && payment.invoice !== '' " @click="downloadInvoice(payment.invoice)" class="invoicebtn text-xl py-2 px-4 rounded-md bg-AccentA-Fade10 hover:bg-AccentA-2 duration-150 cursor-pointer"><i class="fal fa-file-export"></i> </div>
            </div>
        </div>
    </div>




    <div class="bgwrapper bgedit"></div>
</template>

<script>


import Api from "@/Apis/Api";
import Csrf from "@/Apis/Csrf";

export default {
    name: "PaymentsHistory",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            paylist: [],
            countrylist: [],
            loaded: false,
        }
    },
    components: {},
    created() {
        this.$emit('ldstat', false);
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {
        async getPayList() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.paymentshistory'))
                .then(response => {
                    this.loaded = true;
                    if (response.data.length > 0) {

                        this.paylist = response.data;
                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                    }
                    this.$emit('ldstat', false);
                }).catch(error => {
                this.$emit('updmsgs', {type: 'alert', msg: error.response.data.message});
            });

        },

        async downloadInvoice(orderid) {
            window.open('/invoicegeneration/'+orderid);

        },
    },
    beforeMount() {
        this.$emit('ldstat', false);
        this.getPayList();

    },

}
</script>

<style scoped>

</style>
