<template>


    <div class="w-screen h-screen flex flex-col p-8  justify-center items-center justify-items-center z-10 text-PShade-txt mt-32 ">

        <i class="fal fa-user-bounty-hunter text-4xl"></i><br/>
        <h1 class="text-2xl text-PShade-txt flex">  NOT FOUND </h1>
        <p>These aren't the droids you're looking for.</p>

    </div>


    <div class="bgwrapper bgedit"></div>
</template>

<script>



export default {
    name: "NotFound",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat','updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            userobj: [],
            countrylist: [],
        }
    },
    components: {

    },
    created() {
        this.$emit('ldstat', false);
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {



    },
    beforeMount() {
        this.$emit('ldstat', false);
    },

}
</script>

<style scoped>

</style>
