import {createApp} from 'vue';
import App from "@/Init/Frontend.vue";
import router from '@/Routing/frontendroutes';
import "@c/frontend.css";


const frontend = createApp(App);

frontend.use(router);


frontend.directive('click-out',{
    bind () {
        this.event = event => this.vm.$emit(this.expression, event)
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },
    unbind() {
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.event)
    },

    stopProp(event) { event.stopPropagation() }
});
frontend.mount('#frontend');

