<template>

<!--    <FsLightbox ref="lbox"-->
<!--                :showThumbsOnMount="true"-->
<!--                :toggler="lbtoggler"-->
<!--                :sources="itemobj.mediagal"-->
<!--                :thumbs="itemobj.mediagal"-->
<!--                :key="itemobj.id"-->
<!--                type="image"-->
<!--    />-->


    <div class="blogviewer " v-show="showform">
        <div class="blogview overflow-y-auto ">
            <div class="blogheader p-8 sticky top-0 bg-PShade-4">
                <div class="blogtitle flex flex-col  text-PShade-txt">
                    <h1 class="text-3xl flex font-thin">{{ itemobj.name }}</h1>
                    <h3 class="text-base flex font-thin" >{{ new Date(itemobj.created_at).toLocaleDateString('en-US', {day: "numeric", month: "short", year:"2-digit"}) }}</h3>
                </div>
                <div class="closeviewer absolute top-6 right-6 text-PShade-txt text-3xl" @click="hideform"><i class="fal fa-times"></i></div>
            </div>

            <div class="blogcontent text-PShade-txt text-justify p-8">

                <div class="blogtext" v-html="itemobj.article ">

                </div>


            </div>

        </div>
        <router-link to="/blogposts">
            <div class="assetviewerbg"></div>
        </router-link>
    </div>

    <div class="contentwrapper" :class="{blurred: showform}">

        <div class="maincol ">




            <div class="blogpostswrapper mt-32 relative inline-block justify-items-center items-center text-center flex flex-col gap-3  ">

                <div class="flex flex-row w-9/12 bg-PShade-4  flex-nowrap" v-for="(asset,index) in items">
                    <router-link class="flex " :to="'/blogposts/show/' + asset.alias" @click="setIndexItem(index)">


                            <div class="flex-none blogicon relative inline-block w-48 h-48  overflow-hidden" :style="'background-image:url('+getBlogThumb(asset.id,asset.mediagal)+')'">

                            </div>

                            <div class="flex  flex-col blogtitle text-PShade-txt  ml-4 text-justify">
                                <div class="blgominititle text-lg font-thin mt-4 ">{{asset.name}}</div>
                                <div class="blgominititle pr-8 text-sm" v-html="$options.filters.truncate(asset.article,300, '...')" ></div>

                            </div>



                    </router-link>
                </div>




            </div>

        </div>
    </div>

</template>

<script>

import Api from "@/Apis/Api.js";
import Csrf from "@/Apis/Csrf";
import FwBeautyClay from "@/Pages/Frontend/Modules/FwBeautyClay.vue";
import FwPbrCarousel from "@/Pages/Frontend/Modules/FwPbrCarousel.vue";
import FwTextureShow from "@/Pages/Frontend/Modules/FwTextureShow.vue";
import FsLightboxV3 from "fslightbox-vue/v3.js";

const FsLightbox = FsLightboxV3.default || FsLightboxV3;


export default {
    name: "BlogPosts",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            itemid: 0,
            items: [],
            itemscategories: [],
            nesteditemscategories: [],
            assetalias: '',
            itemobj: [],
            textslides: [],
            realslides: [],
            selecteditem: [],
            formisready: false,
            showform: false,
            timeoutinterval: 10,
            lbtoggler: false,
            filtering: {
                searchby: "name",
                searchstring: "",
                sortingby: "id",
                categories: [],
                sortingdirection: "DESC",
                paglimit: 30,
                pagstart: 1,
                page: 1,
                total: 0,
                last_page: 1,
                current_page: 1

            },
        }
    },
    components: {
        FwBeautyClay,
        FwPbrCarousel,
        FwTextureShow,
        FsLightbox
    },
    computed: {


    },

    filters: {
        truncate: function (text, length, suffix) {

            let tmp = document.createElement("DIV");
            tmp.innerHTML = text;
            text =  tmp.textContent || tmp.innerText || "";


            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },


    methods: {




        getBlogThumb(itemdid, itemthumbs){
            let curmediagal = itemthumbs ? JSON.parse(itemthumbs) : [];
            if(curmediagal.length > 0) {
                return "getBlogMedias3/" + itemdid + "/" + curmediagal[0];
            }
            return "";
        },

        handleRouting(params) {
            if (params.action === "" && params.itemparams === "") {
                this.showform = false;
                this.formisready = false;
            }

            if (params.action === "show" && params.itemparams !== "") {
                this.assetalias = params.itemparams;
                this.loadAssetInfos(this.assetalias);
            }
        },

        async loadFileList() {
            Api.post(route('frontend.pbrfilelist'), {'itemid': this.itemid})
                .then(response => {
                    this.realslides = [];
                    this.textslides = [];
                    let tempfilelist = response.data;
                    let realfilelist = response.data;

                    for (let i = 0; i < realfilelist.length; i++) {
                        let acurtex = realfilelist[i];
                        this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/' + acurtex);
                    }

                    this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/pbr/' + this.itemid + '/show/Ref/Beauty.webp');
                    this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/pbr/' + this.itemid + '/show/Ref/Clay.webp');
                    this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/pbr/' + this.itemid + '/show/Ref/Icon_large.webp');
                    this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/pbr/' + this.itemid + '/show/Ref/IconClay_large.webp');

                    console.log(realfilelist);
                    for (let i = 0; i < tempfilelist.length; i++) {
                        let curtex = tempfilelist[i];
                        let filename = curtex.split('/').pop();
                        filename = filename.replace(".webp", "");
                        tempfilelist[i] = filename;
                    }
                    this.textslides = tempfilelist;

                    this.$emit('ldstat', false);
                })
        },

        async loadAssetInfos(assetid) {
            this.$emit('ldstat', true);
            this.processingacquire = false;
            Api.get(route('frontend.blogpost', {'assetid': assetid}))
                .then(response => {
                    this.itemobj = response.data;
                    this.itemid = this.itemobj.id;
                    document.title = 'CgRepo | ' + this.itemobj.name + ' Free 2K jpg Pbr Texture';
                    this.$emit('ldstat', false);
                    this.loadFileList();
                    this.formisready = true;
                    this.showform = true;
                    this.$refs.lbox.$forceUpdate();
                })
                .catch(error => {
                    if (error.response.status !== 200 || error.response.status !== 201) {
                        var infoerror = error.response.data.errors.err[0].split("|");
                        this.$emit('updmsgs', {type: infoerror[0], msg: infoerror[1]});
                        this.$router.push(route('dashboardpage'));
                    }
                });
        },

        setIndexItem(indexnumber) {
            this.selecteditem = indexnumber;
            console.log('and the index is:   ' + indexnumber)
        },





        async reloadAssets() {
            this.$emit('ldstat', true);
            this.items = [];
            this.filtering.page = 1;
            this.filtering.current_page = 1;
            this.maximumreached = false;
            Api.post(route('frontend.blogposts'),this.filtering)
                .then(response => {
                    this.items = response.data.data

                    this.$emit('ldstat', false);

                })
                .catch(error => {
                    if (error.response.status !== 200 || error.response.status !== 201) {
                        this.errors = {info: ['401 - Unauthorized']};
                        this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});

                    }
                });
        },

        reloadAssetsTimeout() {
            clearTimeout(this.timeoutinterval);
            this.timeoutinterval = setTimeout(this.reloadAssets, 450);
        },


        async loadMoreAssets() {
            this.$emit('ldstat', true);
            this.filtering.page = this.filtering.page + 1;
            this.filtering.current_page = this.filtering.current_page + 1;
            if (this.filtering.page >= 10 || this.filtering.page > this.filtering.last_page) {
                this.maximumreached = true;
                this.$emit('ldstat', false);
            } else {
                Api.post(route('frontend.blogposts'), this.filtering)
                    .then(response => {
                        this.items = this.items.concat(response.data.data);
                        this.$emit('ldstat', false);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status !== 200 || error.response.status !== 201) {
                            this.errors = {info: ['401 - Unauthorized']};
                            this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});
                            this.$router.push('/fwsys/login');
                        }
                    });
            }
        },

        hideform() {
            this.showform = false;
            this.formisready = false;
            this.itemobj = [];
            this.$router.push('/pbr');
        },





    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');


    },
    beforeMount() {
        this.$emit('ldstat', true);

        this.reloadAssets();





    },
    created() {
        window.scrolltimeout = 0;
        window.addEventListener('scroll', this.handleScroll);
        this.handleRouting(this.$route.params);

    },
    beforeRouteUpdate(to, from, next) {
        this.handleRouting(to.params);
        next()
    }
}

</script>



<style >
/* Basic editor styles */



</style>
