<template>


    <div v-show="showlicense"
         class="absolute w-screen justify-items-center justify-center items-center text-PShade-txt9 mt-16 pt-16 pb-32 px-8 lg:px-32 2xl:px-64 bg-PShade-6-F90 backdrop-blur-sm z-100">
        <span class="text-3xl mb-8 inline-block"> License Agreement</span>
        <div class="inline-block" v-html="licenseagreement.article"></div>
        <div class="flex flex-row gap-6 justify-center w-full justify-items-center items-center mt-8">
            <div class="grow base-button flex items-center justify-center bg-AccentInfos-okHover hover:bg-AccentInfos-ok" @click="acceptagreement()"> Accept Agreement
            </div>
            <div class="grow base-button flex items-center justify-center bg-AccentInfos-alertHover hover:bg-AccentInfos-alert" @click="showlicenseagreement()"> Decline
                Agreement
            </div>
        </div>
    </div>

    <div class="flex flex-col w-full justify-items-center justify-center items-center text-PShade-txt9 mt-32">

        <div v-show="!userloaded " class="w-full p-8 md:w-128 xl:w-254 z-10 bg-PShade-6-F40 backdrop-blur-sm">
            <i class="fal fa-spinner-third animate-spin mr-4"></i> Loafing Informations.
        </div>


        <div v-show="authinfo === '' && userloaded " class="w-full p-8 md:w-128 xl:w-254 z-10 bg-[rgba(120,0,0,0.3)] backdrop-blur-sm">
            <i class="fal fa-warning mr-4"></i> You need to be logged in before buying something from CgRepo.
        </div>

        <div v-show="authinfo !== '' && authinfo.verified === false && userloaded " class="w-full p-8 md:w-128 xl:w-254 z-10 bg-[rgba(120,0,0,0.3)] backdrop-blur-sm">
            <i class="fal fa-warning mr-4"></i> You need to verify your email before buying from CgRepo.
        </div>

        <div v-show="!datafilled && userloaded" class="w-full p-8 md:w-128 xl:w-254 z-10 bg-[rgba(120,0,0,0.3)] backdrop-blur-sm">
            <i class="fal fa-warning mr-4"></i> Attention you need to fill important informations fields on your user profile before submitting a payment.<br/>
            You will not be able to proceed with payment until your informations will be fullfilled.<br/>
            Plese follow this
            <router-link to="/user/editprofile" class="font-bold m-2"> LINK</router-link>
            to complete your payment profile.
        </div>


        <div class="flex-col  justify-center justify-items-center mt-16 bg-[rgba(0,0,0,0.3)] backdrop-blur-sm z-10">

            <div class="flex flex-col 2xl:flex-row">

                <div class="p-8   justify-center items-center justify-items-center"
                     v-show="userloaded && authinfo !== '' && authinfo.verified ">
                    <div class="flex flex-col justify-start justify-items-start 2xl:border-r pr-9 ">
                        <div class=" mb-6 text-left"> Bill Summary:</div>
                        <div class=" mb-2 text-left"><span class="planprice"> <span class="plancurrency text-sm mr-4">Plan: </span> {{ planinfo.name }}</span></div>
                        <div class=" mb-2 text-left"><span class="planprice"><span class="plancurrency text-sm mr-4">Credits: </span>  {{ planinfo.credits }}</span></div>
                        <div class=" mb-2 text-left"><span class="planprice"><span class="plancurrency text-sm mr-4">Price: </span>  <strong> {{planinfo.price}} € </strong></span>
                        </div>
                    </div>
                </div>

                <div class="  p-8 grow " v-show="userloaded && authinfo !== '' && authinfo.verified ">
                    CgRepo will process the payment using the following informations you provided:<br/><br/>
                    Name : {{ userinfo.firstname }} {{ userinfo.lastname }}<br/>
                    Address : {{ userinfo.address }} - {{ userinfo.zip }} , {{ userinfo.city }} - [{{ userinfo.state }}]<br/>
                    EMail : {{ userinfo.email }}<br/><br/>
                    <div class="inline-block" v-show="userinfo.company !== null || userinfo.vatnumber !== null || userinfo.codfiscale !== null">
                        <span class="text-lg"> Company Tax Informations</span><br/><br/>
                        <span v-if="userinfo.company !== null">Company Name : {{ userinfo.company }}<br/></span>
                        <span v-if="userinfo.vatnumber !== null">Vat Number : {{ userinfo.vatnumber }}<br/></span>
                        <span v-if="userinfo.codfiscale !== null">Codice Fiscale : {{ userinfo.codfiscale }}<br/></span>
                    </div>
                </div>
            </div>

            <div class="flex-none 2xl:flex-row justify-center justify-items-center ">
                <div class=" text-center p-8 grow  z-10 bg-[rgba(0,0,0,0.1)] border-t border-PShade-0" v-show="userloaded && authinfo !== '' && authinfo.verified ">
                    By submitting payment you confirm that all informations you provided are accurate.
                </div>
            </div>

            <div class="flex-none 2xl:flex-row justify-center justify-items-center  text-center">
                <div class="  p-8 grow  z-10 bg-[rgba(0,0,0,0.1)] border-t border-PShade-0" v-show="userloaded && authinfo !== '' && authinfo.verified ">
                    <span class="inline-block text-xs mb-4">Accepted pay methods</span> <br/>
                    <div class="paymethodicon mastercard"></div>
                    <div class="paymethodicon visa"></div>
                    <div class="paymethodicon amex"></div>
                    <div class="paymethodicon googlepay"></div>
                    <div class="paymethodicon applepay"></div>
                    <div class="paymethodicon samsungpay"></div>
                    <div class="paymethodicon vivawallet"></div>

                </div>
            </div>


        </div>


        <div v-if="datafilled && userloaded" v-show="licenseagreed" class="flex flex-row justify-center items-center bg-PShade-6 p-4 mt-8  z-10 rounded-md border border-AccentA-3 mb-8">
            <i class="fal fa-check text-3xl mr-2 text-AccentA-1"></i>
            <span class="text-lg text-AccentA-1"> License Agreed</span>
        </div>


        <div v-if="datafilled && userloaded" @click="processorder" v-show="licenseagreed"
             class="flex flex-col justify-center items-center bg-AccentA-Fade25 p-4 mt-8  z-10 rounded-md border border-AccentA-3 hover:bg-AccentA-Fade25 hover:shadow-lighted-lg hover:border-AccentA-1 duration-200 cursor-pointer backdrop-blur-sm ">
            <i class="fal fa-wallet text-3xl mb-2"></i>
            <span class="text-lg"> Click to Process payment</span>
        </div>

        <div v-if="datafilled && userloaded" @click="showlicenseagreement()" v-show="!licenseagreed"
             class="flex flex-col justify-center items-center bg-AccentA-Fade25 p-4 mt-8  z-10 rounded-md border border-AccentA-3 hover:bg-AccentA-Fade25 hover:shadow-lighted-lg hover:border-AccentA-1 duration-200 cursor-pointer ">
            <i class="fal fa-check-to-slot text-3xl mb-2"></i>
            <span  class="text-lg"> Read and accept License agreement</span>
        </div>


    </div>

    <div class="bgwrapper bgsubscriptions"></div>
</template>

<script>


import Api from "@/Apis/Api";
import Csrf from "@/Apis/Csrf";


export default {
    name: "BuySubscription",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            planinfo: [],
            userinfo: [],
            licenseagreement: [],
            countrylist: [],
            showlicense: false,
            planloaded: false,
            userloaded: false,
            licenseloaded: false,
            licenseagreed: false,

        }
    },
    components: {},
    computed: {
        datafilled() {
            if (this.userloaded) {
                if (this.userinfo.firstname === null
                    || this.userinfo.lastname === null
                    || this.userinfo.city === null
                    || this.userinfo.countrycode === null
                    || this.userinfo.countryid === null
                    || this.userinfo.countryname === null
                    || this.userinfo.address === null
                    || this.userinfo.region === null
                    || this.userinfo.zip === null) {
                    return false;
                } else {
                    return true;
                }


            }

            return false;
        }
    },
    created() {
        this.$emit('ldstat', false);
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {
        async getPayList() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.getpayplan'), {itemid: this.$route.params.itemid})
                .then(response => {
                    this.planloaded = true;
                    if (response.data.length > 0) {
                        this.planinfo = response.data[0];
                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                    }
                    this.$emit('ldstat', false);
                }).catch(error => {
                this.$emit('updmsgs', {type: 'alert', msg: error.response.data.message});
            });
        },

        async getUserPaymentInfos() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.userpaymentinfos'),)
                .then(response => {
                    this.userloaded = true;
                    this.userinfo = response.data;
                    this.$emit('ldstat', false);
                });
        },


        async getLicenseAgreement() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.webpost'), {aliaswebpost: 'license-agreement'})
                .then(response => {
                    this.licenseloaded = true;
                    this.licenseagreement = response.data;
                    this.$emit('ldstat', false);
                });
        },

        async processorder(){
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.processpaymentcreateorder'),{planid:this.$route.params.itemid})
                .then(response => {
                    console.log(response);
                    window.location.href = response.data;
                    this.$emit('ldstat', false);
                });
        },

        showlicenseagreement() {
            this.showlicense = !this.showlicense;
        },
        acceptagreement() {
            this.licenseagreed = true;
            this.showlicenseagreement();
        },

        switchview(nextview) {
            this.show = nextview;
        },
    },
    beforeMount() {
        this.$emit('ldstat', false);
        this.getPayList();
        this.getUserPaymentInfos();
        this.getLicenseAgreement();
    },

}
</script>

<style scoped>

</style>
