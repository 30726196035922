<template>


    <div class="w-screen h-screen flex flex-col p-8  justify-start items-center justify-items-center z-10 text-PShade-txt mt-32 ">


        <div class="formwrapper">
            <div class="flex flex-col md:flex-row mb-4 border-b border-PShade-0">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        Profile
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Your basic account informations , username is public visible. <br/> Fill passwords only if you wish to change the existing one
                    </div>
                </div>

                <div class="flex flex-col grow">
                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="Username" v-model="userobj.name"></fw-input>
                        <fw-input type="email" label="Email (fixed)" v-model="userobj.email" disabled="true"></fw-input>
                    </div>
                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-pass label="Password" v-model="userobj.password"></fw-pass>
                        <fw-pass label="Confirm Password" v-model="userobj.confirmpassword"></fw-pass>
                    </div>
                </div>

            </div>


            <div class="flex flex-col md:flex-row pb-4 mb-4 border-b border-PShade-0">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        Payment
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Those infos are required in order to process your payments correctly
                    </div>

                </div>

                <div class="flex flex-col grow">

                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="First Name" v-model="userobj.first_name"></fw-input>
                        <fw-input type="text" label="Last Name" v-model="userobj.last_name"></fw-input>
                    </div>


                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <div class="w-full">
                            <label class="baselabel relative w-full " :for="'country'">Country</label>
                            <v-select class="relative w-full mt-2.5" :options="countrylist" label="name" :reduce="country => country.id" id="country" name="country"
                                      :placeholder="'Search Country'" v-model="userobj.country_id"/>
                        </div>
                    </div>

                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="City" v-model="userobj.city"></fw-input>
                        <fw-input type="text" label="Region / Province" v-model="userobj.region"></fw-input>
                        <fw-input type="text" label="Zip Code" v-model="userobj.zip"></fw-input>
                    </div>

                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="Address" v-model="userobj.address"></fw-input>
                        <fw-input type="text" label="Phone" v-model="userobj.phone"></fw-input>
                    </div>

                </div>
            </div>


            <div class="flex flex-col md:flex-row pb-4 mb-4 ">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        TAX Informations (Optional)
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Those infos are required only if you wish to process payments as company
                    </div>

                </div>

                <div class="flex flex-col grow">
                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="Company Name" v-model="userobj.company_name"></fw-input>
                        <fw-input type="text" label="Vat Number" v-model="userobj.vatnumber"></fw-input>
                    </div>
                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="Italy Only (Codice Fiscale)" v-model="userobj.codfiscale"></fw-input>
                    </div>
                </div>
            </div>


            <div @click="saveProfile" class="base-button inline-block w-full mt-4 mb-4 justify-center items-center flex">Save your profile</div>


        </div>

    </div>


    <div class="bgwrapper bgedit"></div>
</template>

<script>


import Api from "@/Apis/Api.js";
import FwInput from "@/FrontendComponents/Forms/FwInput.vue";
import FwPass from "@/FrontendComponents/Forms/FwPass.vue";
import FwButton from "@/Pages/Frontend/Modules/FwButton.vue";
import vSelect from "vue-select";
import Csrf from "@/Apis/Csrf.js";


export default {
    name: "EditProfile",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat','updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            userobj: [],
            countrylist: [],
        }
    },
    components: {
        FwPass, FwInput, vSelect, FwButton
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {

        async saveProfile() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();

            Api.post(route('frontend.updateuserinfo'), this.userobj)
                .then(response => {
                    this.$emit('ldstat', false);
                    this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                    this.$emit('updateuserinfos',true);
                });

        }
    },
    beforeMount() {
        this.$emit('ldstat', true);

        Api.get(route('frontend.getuserinfos'))
            .then(response => {
                this.userobj = response.data;

                // this.$emit('updmsgs', {type: "info", msg: "Info Loaded Correctly"});
            })
            .catch(error => {
                if (error.response.status !== 200 || error.response.status !== 201) {
                    var infoerror = error.response.data.errors.err[0].split("|");
                    this.$emit('updmsgs', {type: infoerror[0], msg: infoerror[1]});
                    this.$router.push(route('dashboardpage'));
                }
            });


        Api.get(route('frontend.countrylist'))
            .then(response => {
                this.countrylist = response.data;
            })
            .catch(error => {
                if (error.response.status !== 200 || error.response.status !== 201) {
                    this.errors = {info: ['401 - Unauthorized']};
                    this.$router.push(route('dashboard'));
                }
            });
    }
}
</script>

<style scoped>

</style>
