<template>

    <FsLightbox ref="lbox"
        :showThumbsOnMount="true"
        :toggler="lbtoggler"
        :sources="realslides"
        :thumbs="realslides"
        :key="itemobj.id"
        type="image"
    />


    <div class="assetviewer" v-show="showform">
        <div class="assetsheet">
            <div class="assetheader">
                <div class="assettitle flex">
                    <div class="flex justify-center items-center mr-4" v-show="authinfo !== '' && itemobj.acquiredid !== null ">
                        <div class="w-4 h-4 rounded-full bg-AccentInfos-ok mr-2"></div>
                        <div class="text-AccentInfos-ok flex ">OWNED </div>

                    </div>

                <h1 class="flex font-light">{{ itemobj.name }}</h1>
                </div>
                <div class="closeviewer" @click="hideform"><i class="fal fa-times"></i></div>
            </div>

            <div class="assetcontent" ref="assetsheet">
                <iframe :src="itemobj.video_url" width="100%" height="350px" allowfullscreen allow="*"></iframe>
                <fw-sbsar-carousel
                    :slidemedias="itemobj"
                />

                <div class="bg-PShade-4 flex flex-row" key="palette" v-show="palette && palette.length > 2">

                            <div v-for="palcol in palette" class="minipalcol w-full m-0 h-4 inline-block  " :style="'background-color: #'+palcol+';'"></div>


                </div>



                <div class="assetcontentblock">

                    <div class="contenticonwrapper w-96" @click="lbtoggler = !lbtoggler">
                        <div  class="iconhover"><i class="fal fa-image"></i></div>
                        <div  class="iconhovertext">Image Gallery</div>
                        <img v-if="itemobj.id !== undefined" :src="'https://cgrepos3public.s3.eu-south-1.amazonaws.com/sbsar/'+itemobj.id+'/Icon.webp'"
                             alt="">
                    </div>

                    <div class="contentdescriptionwrapper">
                        {{ itemobj.name }} is a (Metallic/Roughness Workflow) Substance 3D (previously Allegorithmic Substance Designer) SBSAR material.<br/>
                        This Smart Material is available for download in SBSAR Format, you will be able to output textures from this material using Substance Player.
                        Available for free download from Adobe website, or using the SBSAR in all major 3d softwares or RT Engines like Unreal after installing respectives Substance Plugins. <br/>
                        NOTE : Free 2k Jpg Textures Packs of the included presets can be downloaded from <router-link to="/pbr" class="font-bold text-AccentA-1"> Pbr Materials </router-link> check them out!
                        <div class="tagswrapper">
                            Tags :
                            <div class="tag" v-for="tag in currentags">
                                {{ tag }}
                            </div>
                        </div>
                    </div>

                    <div class="acquiringwrapper">

                        <div class="processingacquire" v-show="processingacquire">
                            <span class="animate-spin text-PShade-txt text-2xl inline-block"><i class="fal fa-spinner-third"></i> </span>
                            <div class="creditscost"></div>
                            <div class="smalltext text-PShade-txt text-sm mt-4">Wait Please..<br/> We are processing your acquire. <br/> Amount: {{ itemobj.credits }}
                                credits.
                            </div>
                        </div>

                        <div class="acquirebutton" v-show="authinfo === '' " @click="emitLogin">
                            <div class="creditscost">{{ itemobj.credits }}</div>
                            <div class="smalltext">CREDITS</div>
                            <div class="acquiretext"><span class="text-xs">Login to get {{ itemobj.name }} PBR</span></div>
                        </div>

                        <div class="acquirebutton free" v-show="authinfo === '' " @click="emitLogin">
                            <div class="creditscost mb-2"></div>
                            <div class="smalltext "><i class="fal fa-download text-lg mr-2"></i> FREE 2K JPG (After Login)</div>
                        </div>

                        <div class="acquirebutton" v-show="authinfo !== '' && itemobj.acquiredid === null && !processingacquire " @click="processAcquire">
                            <span class="z-50 animate-ping top-[50%] left-[50%] -ml-6 absolute inline-flex w-12 h-12 rounded-full bg-AccentA-2 opacity-75"></span>
                            <div class="creditscost">{{ itemobj.credits }}</div>
                            <div class="smalltext">CREDITS</div>
                            <div class="acquiretext"><i class="fal fa-wallet"></i> <br/> <br/><span class="text-xs">Acquire {{ itemobj.name }} </span></div>
                            <div class="acquiretext"><span class="text-base"> SBSAR Premium Package </span></div>
                        </div>

                        <div class="downloadbutton" v-show="authinfo !== '' && itemobj.acquiredid !== null ">
                            <div class="downloadicon animate-bounce"></div>
                            <div class="text-sm text-PShade-txt">Download SBSAR Generator</div>

                            <div class="dwngroup ">
                                <div class="grouptype flex-row"><i class="fal fa-download"></i></div>

                                <div @click="downloadasset(1,8)"  class="groupitem flex-row last" > DOWNLOAD
                                </div>
                            </div>


                        </div>



                        <div class="flex flex-row text-PShade-txt mt-2 justify-center items-center justify-items-center gap-3">
                            <div class="flex border border-SShade-8-F40 grow p-2 flex items-center justify-items-center text-xs" ><i class="fal fa-analytics ml-2 mr-2 text-lg "></i> {{itemobj.hits}} </div>
                            <div class="flex border border-SShade-8-F40 grow p-2 flex items-center justify-items-center text-xs" ><i class="fal fa-money-bill-trend-up ml-2 mr-2 text-lg "></i> {{itemobj.downloads}} </div>

                        </div>

                    </div>

                </div>
                <fw-texture-show-base @click="lbtoggler = !lbtoggler" :itemid="itemobj.id" :slidemedias="realslides" :fullpath="true" :filepath="''"/>

            </div>

        </div>
        <router-link to="/sbsar">
            <div class="assetviewerbg"></div>
        </router-link>
    </div>

    <div class="contentwrapper" :class="{blurred: showform}">


        <div class="maincol">

            <div class="filterbar">
                <div class="categoriesfilteringwrapper">
                    <div v-for="cat in filtering.categories" class="catfiltered" @click="categorySelection(cat)">
                        <div class="catfilteredname">{{ computedcatname(cat) }}</div>
                        <div class="catfilteredclose"><i class="fal fa-times inline-block"></i></div>
                    </div>
                </div>
                <div class="searchinput">
                    <i class="fal fa-search"></i>
                    <input type="text" class="searchassets" :placeholder="filteringplaceholder" v-model="filtering.searchstring" @keyup="reloadAssetsTimeout"/>
                    <div class="searchclear" @click="clearSearch"><i class="fal fa-trash inline-block"></i></div>
                </div>
            </div>

            <div class="assetwrapper p-6  pt-24 relative  text-center justify-items-center items-center justify-center flex gap-4 flex-wrap">
                    <router-link :to="'/sbsar/show/' + asset.alias" @click="setIndexItem(index)" class="sbsarwrapper" v-for="(asset,index) in items">

                        <div v-show="asset.acquiredid && asset.acquiredid !== null" class="overasseticon">
                            <div class="overassetglow"></div>
                        </div>

                        <div class="asseticon text-center"><img :src="'https://cgrepos3public.s3.eu-south-1.amazonaws.com/sbsar/' + asset.id +'/Icon.webp'"></div>
                        <div class="rightwrapper 2xl:mt-8">
                            <div class="iconassetname text-PShade-txt">{{ asset.name }}</div>

                            <div class="assetspecs text-PShade-txt mt-4"> <i class="fal fa-diagram-sankey"></i>&nbsp; {{ asset.presets }}<sup>+ </sup> Presets</div>
                            <div class="assetspecs text-PShade-txt mt-4"> <i class="fal fa-code-branch"></i>&nbsp; More than {{ asset.presets * 10 }} Combinations</div>
                            <div class="assetspecs text-PShade-txt mt-4"> <i class="fal fa-high-definition"></i>&nbsp; 8K Output tested</div>
                            <div class="assetspecs text-PShade-txt mt-4"> <i class="fal fa-person-arrow-up-from-line"></i>&nbsp; Metric sizing</div>
                            <div class="assetspecs text-PShade-txt mt-4"> <i class="fal fa-claw-marks"></i>&nbsp; Metallic / Roughness PBR Workflow</div>


                        </div>
                    </router-link>


            </div>

        </div>
    </div>

</template>

<script>

import Api from "@/Apis/Api.js";
import Csrf from "@/Apis/Csrf";
import FwBeautyClay from "@/Pages/Frontend/Modules/FwBeautyClay.vue";
import FwSbsarCarousel from "@/Pages/Frontend/Modules/FwSbsarCarousel.vue";
import FwTextureShowBase from "@/Pages/Frontend/Modules/FwTextureShowBase.vue";
import FsLightboxV3 from "fslightbox-vue/v3.js";
const FsLightbox = FsLightboxV3.default || FsLightboxV3;

export default {
    name: "SbsarAssets",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            itemid: 0,
            items: [],
            itemscategories: [],
            nesteditemscategories: [],
            assetalias: '',
            itemobj: [],
            textslides: [],
            realslides:[],
            selecteditem: [],
            formisready: false,
            showform: false,
            maximumreached: false,
            processingacquire: false,
            timeoutinterval: 10,
            lbtoggler: false,
            filtering: {
                searchby: "name",
                searchstring: "",
                sortingby: "id",
                categories: [],
                sortingdirection: "DESC",
                paglimit: 30,
                pagstart: 1,
                page: 1,
                total: 0,
                last_page: 1,
                current_page: 1

            },
        }
    },
    components: {
        FwSbsarCarousel,
        FwBeautyClay,
        FwTextureShowBase,
        FsLightbox
    },
    computed: {
        currentags() {
            if (this.itemobj.tags) {
                let temp = this.itemobj.tags
                return temp.split(" ");
            }
        },
        palette() {
            if(this.itemobj.palette){
                let jsonstring = JSON.parse(this.itemobj.palette);
                if(jsonstring.length >= 9){
                    jsonstring.length = 8;
                }
                return jsonstring;
            }
        },
        filteringplaceholder(){
            if(this.filtering.categories.length > 0){
                let appender = 'Search on : ';
                for(let i=0; i< this.filtering.categories.length; i++){
                    let curcat = this.filtering.categories[i];
                    appender += " " + this.computedcatname(curcat);
                }

                return appender;
            }else{
                return 'Search whole Sbsar library..';
            }
            return 'Search whole Sbsar library..';
        },
    },
    methods: {

        scrollToTop(){
            this.$refs.assetsheet.scrollTop = 0;
        },
        computedcatname(catid) {
            let catobj = this.itemscategories.find(cat => parseInt(cat.id) === catid);
            if (catobj) {
                return catobj.name;
            }
        },

        checkifcatselected(catid) {
            let catobj = this.filtering.categories.find(cat => parseInt(cat) === catid);
            if (catobj) {
                return true;
            } else {
                return false
            }
        },

        handleScroll(event) {
            clearTimeout(window.scrolltimeout);
            const scrollHeight = event.target.scrollingElement.scrollHeight
            const scrollTop = event.target.scrollingElement.scrollTop
            const clientHeight = event.target.scrollingElement.clientHeight
            if (this.showform === false) {
                if (scrollTop + clientHeight >= scrollHeight + -1200) {
                    window.scrolltimeout = setTimeout(this.loadMoreAssets, 150);
                }
            }
            let assetsicons = document.getElementsByClassName("iconwrapper");
            for (var i = 0; i < assetsicons.length; i++) {
                let curasseticonpos = assetsicons.item(i).getBoundingClientRect();
                let offtop = curasseticonpos.top;
                if (offtop < -350 || offtop > 1800) {
                    assetsicons.item(i).style.visibility = "hidden";
                } else {
                    assetsicons.item(i).style.visibility = "visible";
                }
            }
        },

        handleRouting(params) {
            if (params.action === "" && params.itemparams === "") {
                this.showform = false;
                this.formisready = false;
            }

            if (params.action === "show" && params.itemparams !== "") {
                this.assetalias = params.itemparams;
                this.loadAssetInfos(this.assetalias);
            }
        },


        async loadAssetInfos(assetid) {
            this.$emit('ldstat', true);
            this.processingacquire = false;
            Api.get(route('frontend.showsbsarasset', {'assetid': assetid}))
                .then(response => {
                    this.scrollToTop();
                    this.itemobj = response.data[0];
                    this.itemid = this.itemobj.id;
                    document.title = 'CgRepo | ' + this.itemobj.name + ' Free 2K jpg Pbr Texture';
                    this.$emit('ldstat', false);


                    this.realslides = [];
                    let realfilelist = JSON.parse(this.itemobj.mediagal);
                    console.log(realfilelist);
                    for (let i = 1; i < realfilelist.length; i++) {
                        let acurtex = realfilelist[i];

                        this.realslides.push('https://cgrepos3public.s3.eu-south-1.amazonaws.com/sbsar/'+this.itemobj.id+'/'+acurtex );
                    }
                    // this.$refs.lbox.$forceUpdate();
                    this.formisready = true;
                    this.showform = true;

                })
                .catch(error => {
                   console.log(error);
                });
        },

        setIndexItem(indexnumber) {
            this.selecteditem = indexnumber;
        },

        async processAcquire() {
            this.processingacquire = true;
            Api.post(route('frontend.processacquiresbsar'), {'assetid': this.itemobj.id})
                .then(response => {
                    this.processingacquire = false;
                    if (response.data.infos.success) {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        this.$emit('updateuserinfos', true);
                        //TODO need to refresh the list with new acquired asset
                        this.items[this.selecteditem].acquiredid = 99;
                        //reloadassetinfo
                        this.loadAssetInfos(this.itemobj.alias);

                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                    }
                });
        },

        clearSearch() {
            this.filtering.searchstring = '';
            this.reloadAssetsTimeout();
        },

        async reloadAssets() {
            this.$emit('ldstat', true);
            this.items = [];
            this.filtering.page = 1;
            this.filtering.current_page = 1;
            this.maximumreached = false;
            Api.post(route('frontend.sbsarassets'), this.filtering)
                .then(response => {
                    this.items = response.data.data;
                    this.$emit('ldstat', false);
                    this.filtering.total = response.data.total;
                    this.filtering.last_page = response.data.last_page;
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status !== 200 || error.response.status !== 201) {
                        this.errors = {info: ['401 - Unauthorized']};
                        this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});
                        this.$router.push('/fwsys/login');
                    }
                });
        },

        reloadAssetsTimeout() {
            clearTimeout(this.timeoutinterval);
            this.timeoutinterval = setTimeout(this.reloadAssets, 450);
        },

        categorySelection(catid) {
            let found = false;
            for (let i = 0; i < this.filtering.categories.length; i++) {
                if (parseInt(catid) === this.filtering.categories[i]) {
                    this.filtering.categories.splice(i, 1)
                    found = true;
                }
            }
            if (!found) {
                this.filtering.categories.push(parseInt(catid));
            }

            this.reloadAssets();
        },

        async loadMoreAssets() {
            this.$emit('ldstat', true);
            this.filtering.page = this.filtering.page + 1;
            this.filtering.current_page = this.filtering.current_page + 1;
            if (this.filtering.page >= 10 || this.filtering.page > this.filtering.last_page) {
                this.maximumreached = true;
                this.$emit('ldstat', false);
            } else {
                Api.post(route('frontend.sbsarassets'), this.filtering)
                    .then(response => {
                        this.items = this.items.concat(response.data.data);
                        this.$emit('ldstat', false);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status !== 200 || error.response.status !== 201) {
                            this.errors = {info: ['401 - Unauthorized']};
                            this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});
                        }
                    });
            }
        },

        hideform() {
            this.scrollToTop();
            this.showform = false;
            this.formisready = false;
            this.itemobj = [];
            this.$router.push('/sbsar');
        },

        emitLogin() {
            this.scrollToTop();
            this.hideform();
            this.$emit('updatelogin', true);
        },


        async downloadfreeasset() {
            this.$emit('ldstat', true);
            Api.post(route('frontend.sbsardownloadfree'), {'assetformat': 2, 'size': 2,'assetid':this.itemobj.id , 'assetname':this.itemobj.alias})
                .then(response => {
                    this.$emit('ldstat', false);
                    if(response.data.infos.success === true) {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        window.open(response.data.infos.downloadurl);
                    }else{
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                    }
                });

        },


        async downloadasset(format,size) {
            this.$emit('ldstat', true);
            Api.post(route('frontend.sbsardownload'), {'assetformat': format, 'size': size,'assetid':this.itemobj.id , 'assetname':this.itemobj.alias})
                .then(response => {
                    this.$emit('ldstat', false);
                    if(response.data.infos.success === true) {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        window.open(response.data.infos.downloadurl);
                    }else{
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                    }
                });

        }



    },
    mounted() {
        this.scrollToTop();
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');




    },
    beforeMount() {
        this.$emit('ldstat', true);

        this.reloadAssets();




    },
    created() {
        window.scrolltimeout = 0;
        window.addEventListener('scroll', this.handleScroll);
        this.handleRouting(this.$route.params);

    },
    beforeRouteUpdate(to, from, next) {
        this.scrollToTop();
        this.handleRouting(to.params);
        next()
    }
}

</script>

