<template>
    <div class="item form-group flex flex-grow w-full grow" :class="{'flex-row' :showicon ,  'flex-col' : !showicon}">
        <label class="baselabel" :for="name">{{ label }}</label>

        <textarea :disabled="disabled" @input="onChanged" :class="[{ withicon: showicon },importclass]"  :value="modelValue" ref="fwinfield" class="baseinput w-full grow"   :name="name" :id="id" :placeholder="placeholder">{{modelValue}}</textarea>

    </div>
</template>

<script>
export default {
    name: "FwArea",
    emits: ['update:modelValue','clear'],
    props: {
        modelValue: String,
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        importclass:{
            type:String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        showicon:{
            type:Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        disabled:{
            type:Boolean,
            default: false
        }

    },
    methods:{
        clearInput() {
           this.$emit('update:modelValue', "");
           this.$emit('clear', true);
        }
    },

    setup(props, { emit }) {
        function onChanged(e) {
            emit('update:modelValue', e.currentTarget.value);
        }

        return {
            onChanged
        }
    }
}
</script>

<style scoped>

</style>
