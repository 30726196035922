<template>
    <div class="item form-group flex flex-grow w-full grow" :class="{'flex-row' :showicon ,  'flex-col' : !showicon}">
        <label class="baselabel" :for="name">{{ label }}</label>

        <input :required="required" :disabled="disabled" @input="onChanged" :class="[{ withicon: showicon },importclass]"  :value="modelValue" ref="fwinfield" class="baseinput w-full grow"  :type="type" :name="name" :id="id" :placeholder="placeholder">
        <div @click="clearInput" v-show="showicon" class="inputIcon w-12 pl-3 pr-3 pt-2 pb-2 text-center"> <i class="fal fa-trash"></i> </div>
    </div>
</template>

<script>
export default {
    name: "FwInput",
    emits: ['update:modelValue','clear'],
    props: {
        modelValue: String,
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false,
        },
        importclass:{
            type:String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        showicon:{
            type:Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        disabled:{
            type:Boolean,
            default: false
        }

    },
    methods:{
        clearInput() {
           this.$emit('update:modelValue', "");
           this.$emit('clear', true);
        }
    },

    setup(props, { emit }) {
        function onChanged(e) {
            emit('update:modelValue', e.currentTarget.value);
        }

        return {
            onChanged
        }
    }
}
</script>

<style scoped>

</style>
