<template>


<!--    <div class="w-screen  flex flex-col p-8  justify-start items-start justify-items-start z-10 text-PShade-txt mt-32 ">-->



<!--        <div class="planscatswrapper flex items-center justify-center justify-items-center w-full mb-16 gap-6">-->

<!--            <div class="plancat" :class="{active:  show === 'Individual' }" @click="switchview('Individual')">-->
<!--                <i class="fal fa-user-astronaut text-2xl"></i>-->
<!--                <span class="text-md mt-2"> Individual</span>-->
<!--            </div>-->

<!--            <div class="plancat" :class="{active:  show === 'Studio' }" @click="switchview('Studio')">-->
<!--                <i class="fal fa-users-class text-2xl"></i>-->
<!--                <span class="text-md mt-2"> Studio</span>-->
<!--            </div>-->

<!--            <div class="plancat" :class="{active:  show === 'Enterprise' }" @click="switchview('Enterprise')">-->
<!--                <i class="fal fa-industry text-2xl"></i>-->
<!--                <span class="text-md mt-2"> Enterprise</span>-->
<!--            </div>-->

<!--        </div>-->


<!--        <div class="flex w-full text-center items-center justify-items-center justify-center mb-16"  v-show="show === 'Individual' ">-->
<!--            For individual professionals and small production houses with gross sales not exceeding $ 100k.-->
<!--        </div>-->
<!--        <div class="subscriptionwrapper " v-show="show === 'Individual' ">-->


<!--        <div v-for="plan in individual" class="planblock " :class="{best : plan.name === 'Hero'}">-->
<!--            <div class="flex flex-col justify-center justify-items-center items-center ">-->
<!--                <div class="planvoice mb-4"><span class="planname"> {{ plan.name }}</span></div>-->
<!--                <div class="planvoice credits mb-4"><span class="plancredits"> {{ plan.credits }}</span> <br/> <span class="text-xs">CREDITS</span> </div>-->
<!--                <div class="planvoice mt-4"><span class="planprice text-2xl"> {{ plan.price }}</span> <span class="plancurrency text-xs">€</span></div>-->
<!--                <router-link :to="'/buysubscription/'+ plan.id "><div v-show="authinfo !== ''"  class="planvoice"><span class="paybtn"> Buy {{ plan.name }} </span> </div></router-link>-->
<!--                <div v-show="authinfo === ''" class="planvoice" @click="loginToggle"><span class="paybtn">Login</span> </div>-->

<!--            </div>-->
<!--        </div>-->
<!--        </div>-->


<!--        <div class="flex w-full text-center items-center justify-items-center justify-center mb-16"  v-show="show === 'Studio' ">-->
<!--            For small and mid production houses with gross sales not exceeding  10 Million $ per year.-->
<!--        </div>-->
<!--        <div class="subscriptionwrapper" v-show="show === 'Studio' ">-->
<!--            <div v-for="plan in studio" class="planblock " >-->
<!--                <div class="flex flex-col justify-center justify-items-center items-center ">-->
<!--                    <div class="planvoice mb-4"><span class="planname"> {{ plan.name }}</span></div>-->
<!--                    <div class="planvoice credits mb-4"><span class="plancredits"> {{ plan.credits }}</span> <br/> <span class="text-xs">CREDITS</span> </div>-->
<!--                    <div class="planvoice mt-4"><span class="planprice text-2xl"> {{ plan.price }}</span> <span class="plancurrency text-xs">€</span></div>-->
<!--                    <router-link :to="'/buysubscription/'+ plan.id "><div v-show="authinfo !== ''"  class="planvoice"><span class="paybtn"> Buy {{ plan.name }} </span> </div></router-link>-->
<!--                    <div v-show="authinfo === ''" class="planvoice" @click="loginToggle"><span class="paybtn">Login</span> </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->


<!--        <div class="flex w-full text-center items-center justify-items-center justify-center mb-16"  v-show="show === 'Enterprise' ">-->
<!--            For mid and large enterprises with gross sales exceeding  10 Million $ per year.-->
<!--        </div>-->
<!--        <div class="subscriptionwrapper" v-show="show === 'Enterprise' ">-->
<!--            <div v-for="plan in enterprise" class="planblock " >-->
<!--                <div class="flex flex-col justify-center justify-items-center items-center ">-->
<!--                    <div class="planvoice mb-4"><span class="planname"> {{ plan.name }}</span></div>-->
<!--                    <div class="planvoice credits mb-4"><span class="plancredits"> {{ plan.credits }}</span> <br/> <span class="text-xs">CREDITS</span> </div>-->
<!--                    <div class="planvoice mt-4"><span class="planprice text-2xl"> {{ plan.price }}</span> <span class="plancurrency text-xs">€</span></div>-->
<!--                    <router-link :to="'/buysubscription/'+ plan.id "><div v-show="authinfo !== ''"  class="planvoice"><span class="paybtn"> Buy {{ plan.name }} </span> </div></router-link>-->
<!--                    <div v-show="authinfo === ''" class="planvoice" @click="loginToggle"><span class="paybtn">Login</span> </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->




<!--    </div>-->
    <div class="flex w-full justify-items-center justify-center items-center text-PShade-txt9 mt-8">
    <div class=" w-full p-8 md:w-128 xl:w-254 z-10 bg-[rgba(0,0,0,0.3)] backdrop-blur-sm">
        Payments and credits buying are suspended.
      
        CGRepo subscriptions contain a certain number of credits that can be used to acquire assets, credits will never expire and you always will be able to download previously acquired assets. <br/><br/>

        CGRepo subscriptions are <span class="font-bold text-AccentA-1 inline m-2 ">NOT RECURRING</span>, and <span class="font-bold inline m-2  text-AccentA-1">WILL NOT</span> be automatically renewed ( peace of mind ). <br/><br/>



        Consuming Credits (Acquiring) assets from CgRepo with the purpose of creating a library for sale (a website or a marketplace similar to cgrepo) is not allowed.

        CgRepo Assets are intended to be used as "PART" of your projects , that's why delivering to the public, or in any way, sharing CGRepo assets  "as is" or "without modifications"  on any platform without CgRepo approval is strictly prohibited.

    </div>

    </div>

    <div class="bgwrapper bgsubscriptions"></div>
</template>

<script>


import Api from "@/Apis/Api";
import Csrf from "@/Apis/Csrf";

export default {
    name: "ListPlansCredits",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            planlist: [],
            individual:[],
            studio:[],
            enterprise:[],
            countrylist: [],
            loaded: false,
            show: 'Individual',
        }
    },
    components: {},
    created() {
        this.$emit('ldstat', false);
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {
        async getPayList() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.payplans'))
                .then(response => {
                    this.loaded = true;
                    if (response.data.length > 0) {
                        this.planlist = response.data;
                        this.individual = [];
                        this.studio = [];
                        this.enterprise = [];


                        for(let i=0; i<this.planlist.length; i++){
                            if(this.planlist[i].catname == 'Individual'){
                                this.individual.push(this.planlist[i]);
                            }
                            if(this.planlist[i].catname == 'Studio'){
                                this.studio.push(this.planlist[i]);
                            }
                            if(this.planlist[i].catname == 'Enterprise'){
                                this.enterprise.push(this.planlist[i]);
                            }

                        }



                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                    }
                    this.$emit('ldstat', false);
                }).catch(error => {
                this.$emit('updmsgs', {type: 'alert', msg: error.response.data.message});
            });

        },
        switchview(nextview){
            this.show = nextview;
        },
        loginToggle(){
            this.$emit('updatelogin',true);
        }

    },
    beforeMount() {
        this.$emit('ldstat', false);
        this.getPayList();

    },

}
</script>

<style scoped>

</style>
