<template>

    <div  class="texturescarousel bg-PShade-7-F90 py-8 overflow-visible">
    <carousel  ref="texturecarousel" :settings="settings" :breakpoints="breakpoints" :autoplay="2000">
        <slide v-for="slide in slidemedias" key="bigsize">
            <div class="minitexture " :data-src="filepath+itemid+'/show/Free/' + slide + '.webp'"  data-rel="gallery1">
               <img  :src="slide" />
            </div>
        </slide>


    </carousel>
    </div>
</template>

<script>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import {ref} from "vue";
import Api from "@/Apis/Api.js";

const texturecarousel = ref(null)

export default {
    name: "FwTexturesShowBase",
    props: ['filepath', 'itemid','slidemedias','fullpath'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data(){
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround:false,
                pauseAutoplayOnHover:true,
            },
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                1200:{
                    itemsToShow: 4,
                    snapAlign: 'center',
                },
                1600: {
                    itemsToShow: 6,
                    snapAlign: 'center',
                },
                2900: {
                    itemsToShow: 6,
                    snapAlign: 'center',
                },

            },
        }
    },
    computed:{


    },
    methods:{
    },

    watch:{
        slidemedias : function(){
            setTimeout(this.$refs.texturecarousel.updateSlideWidth, 800);
        }
    },
    mounted() {
        setTimeout(this.$refs.texturecarousel.updateSlideWidth, 800);
    },


}
</script>

<style scoped>

</style>
