import {createRouter, createWebHistory} from 'vue-router';
import Index from "@/Pages/Frontend/Index.vue";
import TopBar from "@/Pages/Frontend/UI/TopBar.vue";

//pages
import Homepage from "@/Pages/Frontend/Pages/Homepage.vue";
import PbrMaterials from "@/Pages/Frontend/Pages/PbrMaterials.vue";
import EditProfile from "@/Pages/Frontend/Pages/EditProfile.vue";
import ComingSoon from "@/Pages/Frontend/Pages/ComingSoon.vue";
import PaymentsHistory from "@/Pages/Frontend/Pages/PaymentsHistory.vue";
import ListPlansCredits from "@/Pages/Frontend/Pages/ListPlansCredits.vue";
import BuySubscription from "@/Pages/Frontend/Pages/BuySubscription.vue";
import WebPageShow from "@/Pages/Frontend/Pages/WebPageShow.vue";
import ContactForm from "@/Pages/Frontend/Pages/ContactForm.vue";
import NotFound from "@/Pages/Frontend/Errors/NotFound.vue";
import BlogPosts from "@/Pages/Frontend/Pages/BlogPosts.vue";
import SbsarAssets from "@/Pages/Frontend/Pages/SbsarAssets.vue";


const router = createRouter({
    mode : 'history',
    history: createWebHistory(),
    routes: [

        {
            path: '/',
            name: 'homepage',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Homepage - Cg Textures Pbr Materials and more',
                routecolor: 'rgb(64,230,210)',
            },
            children :[{
                path: '',
                name: 'dashmain',
                components: {
                    content : Homepage,
                    topbar:TopBar,
                }
            }],

        },

        {
            path: '/user/editprofile',
            name: 'editprofile',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | User profile - Cg Textures Pbr Materials and more',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: '',
                name: 'editprofile',
                components: {
                    content : EditProfile,
                    topbar:TopBar,
                }
            }],

        },

        {
            path: '/contact',
            name: 'contact',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Contact - Cg Textures Pbr Materials and more',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: '',
                name: 'contact',
                components: {
                    content : ContactForm,
                    topbar:TopBar,
                }
            }],

        },


        {
            path: '/user/paymentshistory',
            name: 'paymentshistory',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | User payments history',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: '',
                name: 'paymentshistory',
                components: {
                    content : PaymentsHistory,
                    topbar:TopBar,
                }
            }],

        },

        {
            path: '/subscriptions',
            name: 'subscriptions',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Credits Subscriptions',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: '',
                name: 'subscriptions',
                components: {
                    content : ListPlansCredits,
                    topbar:TopBar,
                }
            }],
        },

        {
            path: '/buysubscription/:itemid?',
            name: 'buysubscription',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Buy Subscriptions',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: '',
                name: 'buysubscription',
                components: {
                    content : BuySubscription,
                    topbar:TopBar,
                }
            }],
        },


        {
            path: '/pages',
            name: 'pages',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Terms & Conditions',
                routecolor: 'rgb(237,113,192)',
            },
            children :[{
                path: ':pagealias',
                name: 'pageread',
                components: {
                    content : WebPageShow,
                    topbar:TopBar,
                }
            }],
        },

        {
            path: '/blogposts/:action?/:itemparams?',
            name: 'blogposts',
            component : Index,
            props: {
                menuitemid: 0,
                pagetitle: 'CgRepo | Blogposts',
                routecolor: 'rgb(36, 197, 229)',
            },
            children :[{
                path: '',
                name: 'blogposts',
                components: {
                    content : BlogPosts,
                    topbar:TopBar,
                }
            }],

        },




        {
            path: '/sif/:action?/:itemparams?',
            name: 'sif',
            component : Index,
            props: {
                menuitemid: 2,
                pagetitle: 'CgRepo | Surface Imperfections - Coming soon',
                routecolor: 'rgb(150,113,237)',
            },
            children :[{
                path: '',
                name: 'sif',
                components: {
                    content : ComingSoon,
                    topbar:TopBar,
                }
            }],

        },

        {
            path: '/sbsar/:action?/:itemparams?',
            name: 'sbsar',
            component : Index,
            props: {
                menuitemid: 3,
                pagetitle: 'CgRepo | Substance Smart Generators',
                routecolor: 'rgb(237,169,113)',
            },
            children :[{
                path: '',
                name: 'sbsar',
                components: {
                    content : SbsarAssets,
                    topbar:TopBar,
                }
            }],

        },


        {
            path: '/pbr/:action?/:itemparams?',
            name: 'pbrmaterialslist',
            component : Index,
            props: {
                menuitemid: 1,
                pagetitle: 'CgRepo | Pbr Materials - Free 2k PBR Textures for commercial use',
                routecolor: 'rgb(36, 197, 229)',
            },
            children :[{
                path: '',
                name: 'pbrmaterialslist',
                components: {
                    content : PbrMaterials,
                    topbar:TopBar,
                }
            }],

        },





        {
            path: '/:pathMatch(.*)*',
            name: 'notfound',
            component : Index,
            props: {
                menuitemid: 2,
                pagetitle: 'CgRepo | Not Found',
                routecolor: 'rgb(150,113,237)',
            },
            children :[{
                path: '',
                name: 'notfound',
                components: {
                    content : NotFound,
                    topbar:TopBar,
                }
            }],

        },

    ],

});
export default router;
