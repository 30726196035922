<template>




    <div v-show="notfound" class="w-screen h-screen flex flex-col p-16 justify-center  items-center justify-items-center z-10 text-PShade-txt mt-32  pb-32">

        <h1 class="mb-12 text-4xl"><i class="fal fa-light-emergency-on"></i> </h1>
        <h1 class="mb-4">404</h1>
        <h3 class="mb-12">NOT FOUND</h3>




    </div>


    <div v-show="articleloaded" class="w-screen flex flex-col px-16 xl:px-32 2xl:px-64 text-justify items-start justify-items-start z-10 text-PShade-txt mt-32  pb-32">

        <h1 class="mb-12">{{article.name}} </h1>

        <p v-html="article.article">

        </p>

    </div>


    <div class="bgwrapper bgclean"></div>
</template>

<script>



import Csrf from "@/Apis/Csrf.js";
import Api from "@/Apis/Api.js";

export default {
    name: "WebPageShow",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat','updateuserinfos'],


    data() {
        return {
            articlealias: '',
            itemid: 2,
            article: [],
            articleloaded:false,
            articletitle : '',
            notfound:false,
        }
    },
    components: {

    },
    created() {
        this.$emit('ldstat', false);
        this.getPageText();
    },
    mounted() {
        this.$emit('updtitle', 'Homepage');

            this.$emit('ldstat', true);


    },
    methods: {

        async getPageText() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.webpost'), {aliaswebpost: this.articlealias })
                .then(response => {
                    this.article = response.data;
                    if(this.article === ''){
                        this.articleloaded = false;
                        this.notfound = true;
                    }else{
                        this.articleloaded = true;
                        document.title = "CgRepo | "+this.article.name;
                    }
                    this.$emit('ldstat', false);
                });
        },

    },
    beforeMount() {
        this.$emit('ldstat', false);
        this.articlealias = this.$route.params.pagealias;
    },

    beforeRouteUpdate(to, from, next) {
        // Call the API query method when the URL changes
        this.$emit('updatemenu',false);
        this.$emit('updatelogin',false);
        this.$emit('updatefavs',false);
        this.$emit('updateowned',false);



        this.articlealias = to.params.pagealias;
        this.getPageText();
        next()
    }

}
</script>

<style scoped>

</style>
