<template>
    <div class="form-group flex flex-col mb-4 border-none p-0 flex-grow ">
        <label class="baselabel" :for="name">{{ label }}</label>
        <div class="flex flex-row">
            <input  @input="onChanged" ref="fwinfield"  class="truncatedinput w-full" :type="fieldtype" :name="name" :id="id" :placeholder="placeholder" :value="modelValue">
            <div class="truncated_btn" @click="switchpass"><i :class="{'fal fa-low-vision':seepass,'fal fa-eye':!seepass}"></i></div>
        </div>
    </div>
</template>


<script>
export default {
    name: "FwPass",
    emits: ['update:modelValue'],
    props: {
        modelValue: String,
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            seepass:false,
            fieldtype:'password',
        }
    },
    methods: {
        switchpass() {
            this.seepass = !this.seepass;
            if(this.seepass){
                this.fieldtype = 'text';
            }else{
                this.fieldtype = 'password';
            }
        }

    },
    mounted() {
        this.seepass = false;
    },
    setup(props, { emit }) {
        function onChanged(e) {
            emit('update:modelValue', e.currentTarget.value);
        }

        return {
            onChanged
        }
    }

}
</script>

<style scoped>

</style>
