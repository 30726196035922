<template>
    <input type="submit" class="base-button inline-block w-full" :value="inline"/>

</template>

<script>
export default {
    name: "FwSubmit",
    props: {
        inline: {
            type: String,
            required: true,
            default: 'Button'
        }
    }
}
</script>

<style scoped>

</style>
