<template>
    <div class="topmoduleblock">
        <div class="basemodblock mainmenu" :class="{active: isOpenMenu}">
            <div class="p-12 lg:p-18">
                <div class="flex flex-col justify-evenly md:flex-row gap-4 md:gap-32 ">
                    <div class="flex flex-col ">
                        <h3 class="topmenusector">LIBRARY </h3>
                        <div v-for="menuitem in menuitems" v-show="menuitem.published === 1" class="mainmenuitem">
                            <router-link :to="menuitem.route" class="flex">
                                <div class="flex-col assetshort" :style="'color: ' +  menuitem.color  ">{{ menuitem.tag + " |" }}</div>
                                <div class="flex-col assetname"> {{ menuitem.name }}</div>
                            </router-link>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <h3 class="topmenusector">SERVICES</h3>
                        <div v-for="menuitem in subscriptionitems" class="mainmenuitem">
                            <router-link :key="$route.fullPath" :to="menuitem.route" class="flex">
                                <div class="flex-col assetname"> <i :class="'fal fa-'+menuitem.icon" :style="'color:'+ menuitem.color"></i>  &nbsp;  {{ menuitem.name }}</div>
                            </router-link>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <h3 class="topmenusector"> USEFULS </h3>
                        <div v-for="menuitem in websiteitems" class="mainmenuitem ">
                            <router-link :to="menuitem.route" class="flex">
                                <div class="flex-col assetname"> <i :class="'fal fa-'+menuitem.icon" :style="'color:'+ menuitem.color"></i>  &nbsp;  {{ menuitem.name }}</div>
                            </router-link>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <h3 class="topmenusector"> MORE </h3>
                        <div class="mainmenuitem">
                            <div class="flex-col assetshort" style="color: #f14ed3;"><i class="fab fa-instagram text-lg mr-4"></i></div>
                            <div class="flex-col assetname mt-1">Instagram</div>
                        </div>




                        <div class="flex flex-row items-center  mx-1 ">
                            <div class="flex-col assetshort" style="color: #8b79f1;"><i class="fab fa-discord text-lg mr-4"></i></div>
                            <div class="flex-col assetname mt-1">Discord</div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
        <div class="basemodblock loginblock" :class="{active: isOpenLogin}">
            <div class="p-8">

                <div class="loginwrapper" v-show="showreset">

                    <div class="frontenedloginleft">
                        <div class="midlogo mb-4"></div>
                        <div class="registerblock text-center w-64">
                            <span class="prelogtxt text-sm mb-4 inline-block">Password reset request form. <br/> Fill the form, you will receive an email containing a link for resetting your password. (Check SPAM) </span>
                        </div>
                    </div>

                    <div class="frontenedloginform">
                        <form @submit.prevent="submitPasswordReset">
                            <div class="inputgrp-wrapper ">
                                <fw-input type="email" label="Enter your account email" id="email" placeholder="Email" v-model="resetform.email"/>
                                <fw-submit class="w-full" :inline="'Send Reset Link'"/>
                                <div class="preloginaction" @click="toggleResetForm">
                                    <i class="fal fa-arrow-left p-3 bg-AccentA-Fade50 "></i>
                                    <span class="prelogtxt ml-4">Back to Login ? </span>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <div class="loginwrapper" v-show="authinfo === '' && !showreset && !showregister ">

                    <div class="frontenedloginleft">
                        <div class="midlogo mb-4"></div>
                        <div class="registerblock text-center">
                            <span class="prelogtxt text-sm mb-4 inline-block">Don't have an account?</span>
                            <div class="registerbutton" @click="register">
                                <i class="fal fa-user-astronaut p-2 "></i>
                                <span class="prelogtxt ml-2">Create account!</span>

                            </div>
                        </div>
                    </div>

                    <div class="frontenedloginform">
                        <form @submit.prevent="submitLogin">
                            <div class="inputgrp-wrapper ">
                                <fw-input type="email" label="Email" id="email" placeholder="Email" v-model="form.email"/>
                                <fw-pass label="Password" id="password" placeholder="Password" v-model="form.password"/>
                                <fw-submit class="w-full" :inline="'Log In'"/>
                                <div class="preloginaction" @click="toggleResetForm">
                                    <i class="fal fa-key p-3 bg-AccentA-Fade50 "></i>
                                    <span class="prelogtxt ml-4">Forgot your password?</span>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>


                <div class="userwrapper" v-show="authinfo !== '' && authinfo.verified  && !showreset && !showregister">
                    <div class="userleft">
                        <div class="smalllogo mb-4"></div>
                        <div class="creditshow">
                            <div class="crednumber text-center">{{ authinfo.credits }}</div>
                            <div class="credtxt text-sm text-center">CREDITS</div>
                        </div>
                    </div>
                    <div class="useractions">
                        <div class="welcomemsg mb-4"><span class="text-sm">Logged in as : </span> {{ authinfo.username }}</div>

                        <router-link to="/user/editprofile"><div class="useractions-button"><i class="fal fa-user-edit"></i> Edit Your profile</div></router-link>
                        <router-link to="/user/paymentshistory"><div class="useractions-button"><i class="fal fa-money-bill"></i> Payments History</div></router-link>
                        <router-link to="/subscriptions"><div class="useractions-button"><i class="fal fa-coins"></i> Add Credits</div></router-link>
                        <div class="useractions-button" @click="submitLogout"><i class="fal fa-power-off"></i> Logout</div>

                    </div>
                </div>

                <div class="userwrapper" v-show="authinfo !== '' && !authinfo.verified  && !showreset && !showregister">
                    <div class="userleft">
                        <div class="smalllogo mb-4"></div>
                        <div class="creditshow bg-AccentInfos-alertHover">
                            <div class="credtxt text-sm text-center"> Attention! <br/> actions required for activating your account</div>
                        </div>
                    </div>
                    <div class="useractions">
                        <div class="welcomemsg mb-4"><span class="text-sm">Logged in as : </span> {{ authinfo.username }}</div>

                        <div class="useractions-button" @click="resendmailactivation"><i class="fal fa-message"></i> Resend Verification Email</div>
                        <div class="useractions-button" @click="submitLogout"><i class="fal fa-power-off"></i> Logout</div>

                    </div>
                </div>


            </div>
        </div>
        <div class="basemodblock favouritesblock" :class="{active: isOpenFavs}">
            favourites
        </div>
        <div class="basemodblock ownedblock" :class="{active: isOpenOwned}">
            ownassets
        </div>
    </div>


    <div class="topbar flex-grow relative">
        <div class="flex justify-evenly items-center z-10">
            <div class="topmenuvoice flex-col" @click="updatemenu"><i class="topmenuicon fal fa-bars"></i>
                <div class="glow"></div>
            </div>
            <router-link @click="updatecuritem(0)" to="/">
                <div class="flex-col ml-4 mr-5">
                    <div class="smalllogo"></div>
                </div>
            </router-link>
            <div class="menuitems hidden lg:flex ">

                <div v-for="menuitem in menuitems" v-show="menuitem.showontopbar === 1 && menuitem.published === 1"
                     class="flex flex-row items-center  mx-2 asset_menu_item topmenuvoice textvoice" :class="{active: menuitem.id === curitem }">
                    <router-link  :to="menuitem.route" @click="updatecolor(menuitem.color); updatecuritem(menuitem.id)">

                        <div class="flex-col assetshort topmenuicon" :style="'color: ' +  menuitem.color + '; ' ">{{ menuitem.tag + " |" }}</div>
                        <div class="flex-col assetname topmenuicon"> {{ menuitem.name }}</div>
                        <div class="glow" :style="'background-color: ' +  menuitem.color  + '; box-shadow:0px 1px 15px 3px '+menuitem.color+';' "></div>
                    </router-link>
                </div>

            </div>
        </div>

        <div class="flex flex-row items-center justify-end flex-grow z-10">

            <div v-show="authinfo !== ''" class="topmenuvoice flex-col mr-0" @click="updateowned"><i class="topmenuicon fal fa-box"></i>
                <div class="glow"></div>
            </div>


            <div class="topmenuvoice flex-col mr-2" @click="updatelogin"><i class="topmenuicon fal fa-user-astronaut"></i>
                <div class="glow"></div>
            </div>
        </div>
        <div class="topbarindicator" :class="{'loaded':loaded}" :style="'background-color: '+curcolor "></div>
    </div>

    <div @click="closeeverythingtop" class="closetopbar" :class="{active: (isOpenOwned || isOpenFavs || isOpenLogin || isOpenMenu) }">
    </div>


</template>

<script>
import Api from "@/Apis/Api";
import Csrf from "@/Apis/Csrf";
import FwInput from "@/FrontendComponents/Forms/FwInput.vue";
import FwPass from "@/FrontendComponents/Forms/FwPass.vue";
import FwSubmit from "@/FrontendComponents/Forms/FwSubmit.vue";

export default {
    name: "TopBar",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor', 'curitem', 'loaded'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updatecuritem', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],
    components: {
        FwInput, FwPass, FwSubmit
    },

    data() {
        return {
            frontendmenu: [],
            menuitems: [],
            websiteitems: [],
            subscriptionitems: [],
            showreset: false,
            showregister: false,
            form: {
                email: '',
                password: '',
                remember: false
            },
            resetform: {
                email: '',
            }

        }
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    beforeMount() {
        this.$emit('ldstat', true);
        Api.post(route('frontend.frontendmenu'))
            .then(response => {
                let femenuresponse = JSON.parse(response.data[0].json);
                this.frontendmenu = femenuresponse;
                for (let i = 0; i < femenuresponse.length; i++) {
                    let curblock = femenuresponse[i];
                    if (curblock.id == 5) {
                        this.menuitems = curblock.childs;
                    }
                    if (curblock.id == 6) {
                        this.websiteitems = curblock.childs;
                    }
                    if (curblock.id == 9) {
                        this.subscriptionitems = curblock.childs;
                    }
                }
                this.$emit('ldstat', false);
            })
            .catch(error => {
                this.errors = {info: ['401 - Unauthorized']};
                this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});
            });


    },
    methods: {

        async submitPasswordReset() {
            await Csrf.getCookie();
            Api.post(route('frontend.passwordreset'), this.resetform)
                .then(response => {
                    console.log(response);
                    if (response.data === 'passwords.sent') {
                        this.$emit('updmsgs', {
                            type: 'info',
                            msg: 'If your email address exists in our database, you will receive further password recovery instructions in your inbox.'
                        });
                    }
                    if (response.data === 'passwords.throttled') {
                        this.$emit('updmsgs', {type: 'warning', msg: 'You already submitted a password reset for this account, check your inbox and SPAM.'});
                    }
                    if (response.data === 'passwords.user') {
                        this.$emit('updmsgs', {type: 'alert', msg: 'Sorry! wrong email or account not existing!'});
                    }


                });
        },

        async submitLogin() {
            await Csrf.getCookie();
            Api.post(route('frontend.login'), this.form)
                .then(response => {
                    if (response.data.infos.success) {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        this.$router.go();
                        this.$emit('updateuserinfos', true);
                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                    }
                }).catch(error => {
                this.$emit('updmsgs', {type: 'alert', msg: error.response.data.message});
            });
        },

        async submitLogout() {
            await Csrf.getCookie();
            Api.post(route('frontend.logout'), this.form)
                .then(response => {
                    if (response.data.infos.success) {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        this.$router.push('/');
                        this.$emit('updateuserinfos', true);
                    } else {
                        this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                    }
                });
        },

        submitEditProfile() {
            window.location.href = '/user/editprofile';
        },
        submitPayInfo() {
            window.location.href = '/user/paymentshistory';
        },
        submitAddCredits() {
            window.location.href = '/subscriptions';
        },

        resendmailactivation() {
            window.location.href = '/resendmailform';
        },
        register() {
            window.location.href = '/registration';
        },

        toggleResetForm() {
            this.showreset = !this.showreset;
        },
        updatemenu() {
            let tempvar = !this.$props.isOpenMenu;
            this.$emit('updatelogin', false);
            this.$emit('updatefavs', false);
            this.$emit('updateowned', false);
            this.$emit('updatemenu', tempvar);
        },
        updatelogin() {
            let tempvar = !this.$props.isOpenLogin;
            this.$emit('updatemenu', false);
            this.$emit('updatefavs', false);
            this.$emit('updateowned', false);
            this.$emit('updatelogin', tempvar);
        },
        updatefavs() {
            let tempvar = !this.$props.isOpenFavs;
            this.$emit('updatemenu', false);
            this.$emit('updatelogin', false);
            this.$emit('updateowned', false);
            this.$emit('updatefavs', tempvar);
        },
        updateowned() {
            let tempvar = !this.$props.isOpenOwned;
            this.$emit('updatemenu', false);
            this.$emit('updatelogin', false);
            this.$emit('updatefavs', false);
            this.$emit('updateowned', tempvar);
        },
        closeeverythingtop() {
            this.$emit('updatemenu', false);
            this.$emit('updatelogin', false);
            this.$emit('updatefavs', false);
            this.$emit('updateowned', false);

        },
        updatecolor(setcolor) {

            this.$emit('updatecolor', setcolor);
        },
        updatecuritem(itemid) {
            this.$emit('ldstat', false);
            this.$emit('updatecuritem', itemid)
        }
    },


}
</script>

