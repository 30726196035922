<template>

    <fw-slideshow :slidemedias="slideshow" :itemid="itemid" ></fw-slideshow>
    <div class="contentwrapper">

        <div class="maincol p-4 lg:p-16 text-PShade-txt bg-PShade-6 m-0" v-html="article"></div>
    </div>
</template>

<script>
import FwSlideshow from "@/Pages/Frontend/Modules/FwSlideshow.vue";

import Api from "@/Apis/Api.js";
import Csrf from "@/Apis/Csrf.js";
export default {
    name: "Homepage",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo','curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned','updatecolor', 'updmsgs','updtitle','ldstat'],


    data() {
        return {
            slideshow:'',
            itemid:2,
            article: '',
        }
    },
    components:{
        FwSlideshow,
    },

    methods:{

        async getPageText() {
            this.$emit('ldstat', true);
            await Csrf.getCookie();
            Api.post(route('frontend.webpost'), {aliaswebpost: 'homepage' })
                .then(response => {
                    this.article = response.data.article;
                    if(this.article === ''){
                        this.articleloaded = false;
                        this.notfound = true;
                    }else{
                        this.articleloaded = true;
                        document.title = "CgRepo | Homepage - Pbr Materials textures and more..";
                    }
                    this.$emit('ldstat', false);
                });
        },

    },

    mounted(){
        this.$emit('ldstat', false);
        this.$emit('updtitle','Homepage');
    },
    beforeMount() {
        this.$emit('ldstat', true);

        Api.post(route('frontend.slideshow'), {itemid:this.itemid})
            .then(response => {
                this.slideshow = JSON.parse(response.data);
            })
            .catch(error => {
                if (error.response.status !== 200 || error.response.status !== 201) {
                    this.errors = {info: ['401 - Unauthorized']};
                    this.$emit('updmsgs', {type: 401, msg: "Unauthorized"});
                    this.$router.push('/fwsys/login');
                }
            });
        this.getPageText();

    }
}
</script>

<style scoped>

</style>
