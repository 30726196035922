<template>

    <div class="minicarouselwrapper hidden md:block pt-3  bg-PShade-5">
    <carousel ref="minicarousel" :settings="settings" :breakpoints="breakpoints" :autoplay="2000">
        <slide key="bigsize">
            <div class="miniinfo ">
                <div class="minihead ">{{ slidemedias.setsize }}K</div>
                <div class="minititle">XL Package [EXR - JPG]</div>
                <div class="minisubtitle">Premium textures Size</div>
            </div>
        </slide>

        <slide key="freesize">
            <div class="miniinfo">
                <div class="minihead ">{{ ((slidemedias.setsize)/2)/2 }}K</div>
                <div class="minititle">XS Package [JPG]</div>
                <div class="minisubtitle">Free textures size</div>
            </div>
        </slide>

        <slide key="method">
            <div class="miniinfo">
                <div v-show="slidemedias.method === 1" class="minihead "><i class="fal fa-code-fork"></i></div>
                <div v-show="slidemedias.method === 3" class="minihead "><i class="fal fa-camera-retro"></i></div>
                <div v-show="slidemedias.method === 1" class="minititle">Procedural</div>
                <div v-show="slidemedias.method === 3" class="minititle">Photometric</div>
                <div class="minisubtitle">Creation Method</div>
            </div>
        </slide>

        <slide key="method">
            <div class="miniinfo">
                <div  class="minihead ">{{ slidemedias.metric }} <i class="fal fa-person-arrow-up-from-line"></i></div>

                <div class="minititle">Metric</div>

                <div class="minisubtitle">Asset Size in Meters</div>
            </div>
        </slide>


        <slide key="workflow" >
            <div class="miniinfo">
                <div  class="minihead "><i class="fal fa-claw-marks"></i></div>
                <div class="minititle">Metallic/Roughness</div>
                <div class="minisubtitle">PBR Workflow</div>
            </div>
        </slide>

        <slide key="seams" >
            <div class="miniinfo">
                <div  class="minihead "><i class="fal fa-xmarks-lines"></i></div>
                <div class="minititle">Seamless</div>
                <div class="minisubtitle">Tileable textures</div>
            </div>
        </slide>

        <slide key="definition" >
            <div class="miniinfo">
                <div  class="minihead "><i class="fal fa-high-definition"></i></div>
                <div class="minititle">EXR|JPG </div>
                <div class="minisubtitle">Tileable textures</div>
            </div>
        </slide>

        <slide key="plugandplay" >
            <div class="miniinfo">
                <div  class="minihead "><i class="fal fa-plug"></i></div>
                <div class="minititle">Production Ready </div>
                <div class="minisubtitle">Tested in major Engines</div>
            </div>
        </slide>


    </carousel>
    </div>
</template>

<script>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import {ref} from "vue";
const myCarousel = ref(null)

export default {
    name: "FwPbrCarousel",
    props: ['slidemedias', 'itemid'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data(){
        return {
            settings: {
                itemsToShow: 5,
                snapAlign: 'center',
                wrapAround:true,
            },

            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4.5,
                    snapAlign: 'center',
                },
                1600: {
                    itemsToShow: 6,
                    snapAlign: 'center',
                },
                2900: {
                    itemsToShow: 7,
                    snapAlign: 'center',
                },

            },
        }
    },
    computed:{

    },
    watch:{
        slidemedias : function(){
            setTimeout(this.$refs.minicarousel.updateSlideWidth, 800);
        }
    },
    mounted() {
        setTimeout(this.$refs.minicarousel.updateSlideWidth, 800);
    },


}
</script>

<style scoped>

</style>
