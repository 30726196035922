<template>
    <transition name="fade">
        <div v-if="therearemsg" class="msgmodule">


                    <div v-for="message in messages" :class="'messagewrapper ' + message.type + ''">
                        <div class="message">
                        <i class="mt-4 mb-4 text-3xl fal block " :class="{'fa-thumbs-up' : message.type === 'happy', 'fa-check-double' : message.type === 'ok', 'fa-lightbulb-exclamation' : message.type === 'info','fa-bomb' : message.type === 'alert' , 'fa-exclamation-triangle' : message.type === 'warning' } "></i>
                        <span class="text-sm block pt-1" v-html="message.msg">  </span>
                        <div class="messagebutton text-sm block mt-4" @click="hideMsgs"> OK </div>
                        </div>
                        <div class="bgcol"></div>
                    </div>


        </div>
    </transition>



    <div class="flex h-screen flex-col  ">

        <div class="topwrapper">
            <router-view
                name="topbar"
                @ldstat="setloading"
                @updmsgs="addMsg"

                :curcolor="curcolor"
                :isOpenSidebar="isOpenSidebar"
                :isOpenMenu="isOpenMenu"
                :isOpenLogin="isOpenLogin"
                :isOpenFavs="isOpenFavs"
                :isOpenOwned="isOpenOwned"
                :authinfo="authinfo"
                :curitem="curitem"
                :loaded="loaded"
                @updateuserinfos="updateuserinfos"
                @updatemenu="updatemenu"
                @updatelogin="updatelogin"
                @updatefavs="updatefavs"
                @updateowned="updateowned"
                @updatecolor="updatecolor"
                @updatecuritem="updatecuritem"
            ></router-view>
        </div>

        <div class="flex order-2 align-top  w-full h-full">
            <router-view
                name="sidebar"
                @ldstat="setloading"
                :isOpenSidebar="isOpenSidebar"
                :isOpenMenu="isOpenMenu"
                :isOpenLogin="isOpenLogin"
                :isOpenFavs="isOpenFavs"
                :isOpenOwned="isOpenOwned"
                :authinfo="authinfo"
                @updatemenu="updatemenu"
                @updatelogin="updatelogin"
                @updatefavs="updatefavs"
                @updateowned="updateowned"
            ></router-view>

            <div class="flex flex-1 relative">

                <!--region Spinner Loader -->
                <transition name="fade">
                    <div v-show="loading"
                         class="loader fixed w-screen h-screen  z-500 flex items-center justify-center">
                        <transition name="topin">
                            <div v-show="loading">
                                <div class="loadsymcontainer">
                                    <div class="loadersymbol"></div>


                                    <div class="loadermessage">Loading..</div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </transition>
                <!-- endregion-->

                <div class="contentview">
                    <router-view
                        name="content"
                        @updmsgs="addMsg"
                        @updtitle="setpagetitle"
                        @ldstat="setloading"
                        :curcolor="curcolor"
                        :isOpenSidebar="isOpenSidebar"
                        :isOpenMenu="isOpenMenu"
                        :isOpenLogin="isOpenLogin"
                        :isOpenFavs="isOpenFavs"
                        :isOpenOwned="isOpenOwned"
                        :authinfo="authinfo"

                        @updateuserinfos="updateuserinfos"
                        @updatemenu="updatemenu"
                        @updatelogin="updatelogin"
                        @updatefavs="updatefavs"
                        @updateowned="updateowned"
                        @updatecolor="updatecolor"
                    ></router-view>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import Api from "@/Apis/Api.js";

export default {
    name: "Index",
    props:['menuitemid','pagetitle','routecolor'],
    data() {
        return {
            importdata: [],
            authinfo:[],
            isOpenSidebar: false,
            isOpenMenu: false,
            isOpenLogin: false,
            isOpenFavs: false,
            isOpenOwned: false,
            pageTitle: '',
            pageIcon:'',
            isActive: false,
            darkmode:true,
            loading: true,
            messages: [],
            therearemsg: false,
            curcolor: '',
            curitem:0,
            loaded:false,
        }
    },
    beforeMount() {

        Api.get(route('frontend.getuserinfo'))
            .then(response => {
                this.authinfo = response.data;
            });

    },
    methods: {

        async updateuserinfos(){
            Api.get(route('frontend.getuserinfo'))
                .then(response => {
                    this.authinfo = response.data;
                });

        },
        updatecuritem(variable) {
            this.curitem = variable
        },
        updatesidebar(variable) {
            this.isOpenSidebar = variable
        },
        updatemenu(variable) {
            this.isOpenMenu = variable
        },
        updatelogin(variable) {
            this.isOpenLogin = variable
        },
        updatecolor(variable){
            this.curcolor = variable
        },
        updatefavs(variable) {
            this.isOpenFavs = variable
        },
        updateowned(variable) {
            this.isOpenOwned = variable
        },
        setpagetitle(ptitle) {
            this.pageTitle = ptitle
        },
        setpageicon(picon){
            this.pageIcon = picon
        },
        setloading(ldstat) {
            this.loading = ldstat;
            this.loaded = !ldstat;
        },
        addMsg(updmsgs) {
            clearTimeout(window.msgtimer);
            this.messages.push(updmsgs);
            this.therearemsg = true;
            this.checkMsg();
        },
        hideMsgs() {
            this.therearemsg = false;
            this.messages = [];
        },
        checkMsg() {
            if (this.therearemsg) {
                window.msgtimer = setTimeout(this.hideMsgs, 5000);
            }
        },
        toggleDark(){
            var htmlelem = document.getElementsByTagName("html");
            htmlelem[0].classList.toggle("dark");
            if(this.darkmode){
                this.darkmode = false;
            }else {
                this.darkmode = true;

            }
        }
    },
    created() {
        document.title = this.$props.pagetitle;
        this.curitem = this.$props.menuitemid;
        this.curcolor = this.$props.routecolor;

    },
    beforeRouteLeave(to, from, next){
        this.isOpenSidebar =  false;
        this.isOpenMenu = false;
        this.isOpenLogin = false;
        this.isOpenFavs = false;
        this.isOpenOwned = false;
        this.curitem = to.matched[0].props.default.menuitemid;
        document.title = to.matched[0].props.default.pagetitle;
        this.curcolor = to.matched[0].props.default.routecolor;
        next()
    },


}
</script>

<style scoped>




.topin-enter-active,
.topin-leave-active {
    transition: all 0.6s ease;
}

.topin-enter-from,
.topin-leave-to {
    opacity: 0;
    transform: translateY(-20px);

}

.botin-enter-active,
.botin-leave-active {
    transition: all 0.6s ease;
}

.botin-enter-from,
.botin-leave-to {
    opacity: 0;
    transform: translateY(40px);

}


.fade-enter-active,
.fade-leave-active {
    transition: all  0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform:scale(1.4);
}


</style>
