<template>

    <div class="w-screen  flex flex-row p-8 bg-PShade-8-F40 justify-center items-center justify-items-center z-10 text-PShade-txt mt-16 ">

        <a class="opacity-80 hover:opacity-100 cursor-pointer  flex flex-row items-center justify-items-center" href="https://discord.gg/89YZsb3Ky2" target="_blank">
            <i class="fab fa-discord mr-8 text-3xl animate-pulse text-AccentA-1"></i>
            Click here if you want get in touch on DISCORD!
        </a>
    </div>
    <div class="w-screen h-screen flex flex-col p-8  justify-start items-center justify-items-center z-10 text-PShade-txt mt-28 ">


        <div v-if="!messagehasbeensent" class="formwrapper">

            <h1 class="font-light mb-8">Contact Form</h1>
            <div class="flex flex-col md:flex-row mb-4 border-b border-PShade-0">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        Who are you?
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Your basic informations. <br/> Fill your name and your email.
                    </div>
                </div>

                <div class="flex flex-col grow">
                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-input type="text" label="Your Name" v-model="username" required></fw-input>
                        <fw-input type="email" label="Email" v-model="useremail" required></fw-input>
                    </div>


                </div>
            </div>


            <div class="flex flex-col md:flex-row pb-4 mb-4 border-b border-PShade-0">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        Message
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Tell us what we can do for you.
                    </div>

                </div>

                <div class="flex flex-col grow">

                    <div class="inputgrp-wrapper flex-col md:flex-row ">
                        <fw-area type="text" label="Message" v-model="usermessage"></fw-area>

                    </div>


                </div>
            </div>


            <div class="flex flex-col md:flex-row pb-4 mb-4 ">
                <div class="flex flex-col mt-4 mb-4">
                    <div class="text-lg md:w-80">
                        AntiBot
                    </div>
                    <div class="text-sm md:w-80 text-SShade-9">
                        Insert the characters (Case Sensitive) <br/>
                        that you see in the image.
                    </div>

                </div>

                <div class="flex flex-col grow">
                    <div class="inputgrp-wrapper flex-col  ">
                        <div class="flex flex-grow grow w-full rounded-xl overflow-hidden "><img class="w-full " :src="'/getCaptcha'"></div>

                        <fw-input type="text" label="" v-model="captchasolved" required></fw-input>

                    </div>


                </div>


            </div>


            <div @click="sendMessage" class="base-button inline-block w-full mt-4 mb-4 justify-center items-center flex">Send Message</div>


        </div>


        <div v-if="messagehasbeensent" class="flex justify-center justify-items-center items-center flex-col">

            <i class="flex fal fa-thumbs-up text-2xl mb-4"></i>
            <div class="flex">Thank you! your message has been sent, we will reply as soon as possible</div>
        </div>

    </div>


    <div class="bgwrapper bgedit"></div>
</template>

<script>


import Api from "@/Apis/Api.js";
import FwInput from "@/FrontendComponents/Forms/FwInput.vue";
import FwArea from "@/FrontendComponents/Forms/FwArea.vue";
import FwPass from "@/FrontendComponents/Forms/FwPass.vue";
import FwButton from "@/Pages/Frontend/Modules/FwButton.vue";
import vSelect from "vue-select";

import Csrf from "@/Apis/Csrf.js";


export default {
    name: "ContactForm",
    props: ['isOpenMenu', 'isOpenLogin', 'isOpenFavs', 'isOpenOwned', 'isOpenSidebar', 'authinfo', 'curcolor'],
    emits: ['updatemenu', 'updatelogin', 'updatefavs', 'updateowned', 'updatecolor', 'updmsgs', 'updtitle', 'ldstat', 'updateuserinfos'],


    data() {
        return {
            slideshow: '',
            itemid: 2,
            username: '',
            useremail: '',
            usermessage: '',
            countrylist: [],
            captchasolved: '',
            messagehasbeensent: false,
        }
    },
    components: {
        FwPass, FwInput, vSelect, FwButton, FwArea
    },
    mounted() {
        this.$emit('ldstat', false);
        this.$emit('updtitle', 'Homepage');
    },
    methods: {

        solvecaptcha(value) {
            this.captchasolved = value;
        },

        async sendMessage() {
            this.$emit('ldstat', true);
            let csrftoken = await Csrf.getCookie();
            if (this.username !== '' && this.useremail !== '' && this.usermessage !== '') {
                Api.post(route('frontend.sendmessage'), {
                    'username': this.username,
                    'useremail': this.useremail,
                    'usermessage': this.usermessage,
                    'cps': this.captchasolved,
                    'csrf' : csrftoken.config.headers["X-CSRF-TOKEN"],
                })
                    .then(response => {
                        this.$emit('ldstat', false);
                        if (response.data.infos.success === true) {
                            this.messagehasbeensent = true;
                            this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});

                        } else {
                            this.$emit('updmsgs', {type: response.data.infos.status, msg: response.data.infos.message});
                        }
                    });
            } else {
                this.$emit('ldstat', false);
                this.$emit('updmsgs', {type: 'warning', msg: 'Please Fill Data'});

            }
        }


    },
    beforeMount() {
        this.$emit('ldstat', true);


    }
}
</script>

<style scoped>

</style>
