<template>


    <div id="beautyclaywrapper" class="beautyclaywrapper" @mousemove="mouseMove">
        <div id="beautywrapper" class="beautywrapper" :style="'width:' + curwidth +'%; background-image:url(https://cgrepos3public.s3.eu-south-1.amazonaws.com/'+ foldername +'/'+itemid+'/show/Ref/Beauty.webp)'"></div>
        <div id="claywrapper" class="claywrapper" :style="'background-image:url(https://cgrepos3public.s3.eu-south-1.amazonaws.com/'+ foldername +'/'+itemid+'/show/Ref/Clay.webp)'" ></div>
    </div>
</template>

<script>


export default {
    name: "FwBeautyClay",
    props: ['itemname', 'itemid','foldername'],
    data(){
        return{
            curwidth:85,
        }
    },
    components: {

    },
    methods:{
        mouseMove(event) {
            let element = document.getElementById('beautyclaywrapper');
            let offsets = element.getBoundingClientRect();

            let offleft = offsets.left;
            let offtop = offsets.top;
            let offw = offsets.width;
            let offh = offsets.height;

            let calcw = ((event.clientX - offsets.left)/offw)*100;
            this.curwidth = calcw;



        }
    }

}
</script>

<style scoped>

</style>
